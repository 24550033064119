module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","cache_busting_mode":"none","name":"株式会社QualiArts（クオリアーツ）","short_name":"QualiArts","start_url":"/","background_color":"#ffffff","theme_color":"#221815","display":"standalone","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1020,"linkImagesToOriginal":false,"quality":80,"withWebp":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-57ZGPH2F59"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"max_xs":"(max-width: 320px)","min_xs":"(min-width: 321px)","max_sm":"(max-width: 576px)","min_sm":"(min-width: 577px)","max_md":"(max-width: 768px)","min_md":"(min-width: 769px)","max_l":"(max-width: 960px)","min_l":"(min-width: 961px)","max_xl":"(max-width: 1200px)","min_xl":"(min-width: 1201px)","max_xxl":"(max-width: 1400px)","min_xxl":"(min-width: 1401px)"}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
